import * as yup from "yup"

import { Dialog, Transition } from "@headlessui/react"
import { Form, Formik } from "formik"
import { Fragment, useEffect, useRef, useState } from "react"

import EmploymentDetailsFormGroup from "./formGroups/employmentDetailsFormGroup"
import { PencilAltIcon } from "@heroicons/react/outline"

const ProfileModal = ({
	employmentItem,
	open,
	setOpen,
	submitEmploymentHistory,
	saved,
}) => {
	const cancelButtonRef = useRef(null)
	const formRef = useRef()
	const [loaded, setLoaded] = useState(false)
	const [saving, setSaving] = useState(false)
	const [itemDetails, setItemDetails] = useState({
		employerName: "",
		position: "",
		startDate: "",
		endDate: "",
		jobDescription: "",
	})

	const validationSchema = yup.object({
		employerName: yup.string().required("Employer Name is required"),
		position: yup.string().required("Position is required"),
		startDate: yup.string().required("Start Date is required"),
	})

	useEffect(() => {
		if (!loaded) {
			setItemDetails({ ...employmentItem })
			setLoaded(true)
		}
	})

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed z-10 inset-0 "
				initialFocus={cancelButtonRef}
				onClose={setOpen}
			>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
							<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
								<div className="sm:flex sm:items-start">
									<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10">
										<PencilAltIcon className="h-6 w-6" aria-hidden="true" />
									</div>
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title
											as="h3"
											className="text-lg leading-6 font-medium text-gray-900"
										>
											Employment History
										</Dialog.Title>
										<div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 pb-6">
											<Formik
												initialValues={{ ...employmentItem }}
												innerRef={formRef}
												enableReinitialize={true}
												validationSchema={validationSchema}
												onSubmit={async values => {
													await submitEmploymentHistory(values, formRef)
													saved && setOpen(false)
													saved && setSaving(false)
												}}
											>
												<Form className="w-full mt-6 grid grid-cols-4 gap-6">
													<EmploymentDetailsFormGroup />
												</Form>
											</Formik>
										</div>
									</div>
								</div>
							</div>
							<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
								<button
									type="submit"
									className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-800  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
									onClick={() => {
										formRef.current.handleSubmit()
										setSaving(true)
									}}
								>
									{saving ? "Saving.." : "Save"}
								</button>
								<button
									type="button"
									className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
									onClick={() => setOpen(false)}
									ref={cancelButtonRef}
								>
									Cancel
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default ProfileModal
