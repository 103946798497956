import {
	BellIcon,
	CollectionIcon,
	CreditCardIcon,
	DocumentIcon,
	UserCircleIcon,
	UserGroupIcon,
} from "@heroicons/react/outline"
import React, { useEffect, useState } from "react"

function classNames(...classes) {
	return classes.filter(Boolean).join(" ")
}

const items = [
	{
		name: "Profile",
		href: "#details",
		icon: UserCircleIcon,
		current: false,
		id: 1,
	},
	{
		name: "Billing",
		href: "#billing",
		icon: CreditCardIcon,
		current: false,
		id: 2,
	},
	{
		name: "Notifications",
		href: "#notification",
		icon: BellIcon,
		current: false,
		id: 3,
	},
	{
		name: "Certificates",
		href: "#certificates",
		icon: DocumentIcon,
		current: false,
		id: 4,
	},
	{
		name: "Resources",
		href: "#resources",
		icon: CollectionIcon,
		current: false,
		id: 5,
	},
]

const SideNav = ({ setOpenTab, openTab }) => {
	const [loaded, setLoaded] = useState(false)

	const handleClick = async id => {
		setOpenTab(id)
		items.forEach(item => {
			item.current = item.id === id
		})
	}

	useEffect(async () => {
		if (!loaded) {
			items.forEach(item => {
				item.current = item.id === openTab
			})
			setLoaded(true)
		}
	})

	return (
		<aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3  ">
			<nav className="space-y-1 sticky top-0">
				{items.map(item => (
					<a
						key={item.name}
						id={item.id}
						href={item.href}
						onClick={() => handleClick(item.id)}
						className={classNames(
							item.current
								? "bg-gray-50 text-orange-600 hover:bg-white"
								: "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
							"group rounded-md px-3 py-2 flex items-center text-sm font-medium"
						)}
						aria-current={item.current ? "page" : undefined}
					>
						<item.icon
							className={classNames(
								item.current
									? "text-orange-500"
									: "text-gray-400 group-hover:text-gray-500",
								"flex-shrink-0 -ml-1 mr-3 h-6 w-6"
							)}
							aria-hidden="true"
						/>
						<span className="truncate">{item.name}</span>
					</a>
				))}
			</nav>
		</aside>
	)
}

export default SideNav
