import { Link, graphql, navigate, useStaticQuery } from "gatsby"
import React, { useEffect, useRef, useState, useContext } from "react"

import ProfileCard from "./profileCard"
import Resource from "../resources/resource"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import { ProfileContext } from "../../pages/myprofile"

const ResourcesSection = ({ current }) => {
	const loaded = useRef(false)
	const [resources, setResources] = useState([])
	const { getIdTokenClaims } = useAuth0()

	const queryData = useStaticQuery(graphql`
		{
			allFreeResources {
				nodes {
					name
					fileName
					description
					createdDate
					categories
					author
					file
					permalink
				}
			}
		}
	`)

	const allResources = queryData.allFreeResources.nodes
	const { onTabChange } = useContext(ProfileContext)

	useEffect(async () => {
		if (current !== "hidden") {
			// console.log("Resources hit")
			await getResources()
		}
	}, [onTabChange])

	const getMemberResources = async () => {
		// setResources([])
		const tokenClaims = await getIdTokenClaims()
		try {
			const { status, data } = await axios.get(
				`${process.env.GATSBY_API_BASE_URL}/Resources/ListAllMembersResources`,
				{
					headers: {
						Authorization: `Bearer ${tokenClaims.__raw}`,
					},
				}
			)

			if (status) {
				return data
			}
		} catch (err) {
			// console.error(`Error getting resources ${err}`)
			return null
		}
	}

	const getResources = async () => {
		const data = await getMemberResources()

		// Filter allResources for resources that are in data
		const filteredResources = allResources.filter(resource =>
			data.find(r => r.file === resource.file)
		)

		setResources(filteredResources)
	}

	return (
		<div className={current}>
			<ProfileCard title="Resources">
				<ProfileCard.Description description="View your Resources here." />
				<ProfileCard.Item>
					<div className="mt-4 grid grid-cols-1 gap-2">
						{resources.map(r => (
							<Resource key={r.file} resource={r} />
						))}
					</div>
				</ProfileCard.Item>
			</ProfileCard>
		</div>
	)
}

export default ResourcesSection
