import React, { useState, createContext } from "react"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"

import BillingSection from "../components/profile/billingSection"
import CertificateSection from "../components/profile/certificatesSection"
import CommitteesSection from "../components/profile/committeesSection"
import Layout from "../components/layout/layout"
import NotificationsSection from "../components/profile/notificationsSection"
import ProfileDetailsSection from "../components/profile/profileDetailsSection"
import Redirecting from "../components/redirecting"
import ResourcesSection from "../components/profile/resourcesSection"
import SideNav from "../components/profile/sideNav"
import queryString from "query-string"

const TabId = {
	PROFILE: 1,
	BILLING: 2,
	NOTIFICATIONS: 3,
	CERTIFICATES: 4,
	RESOURCES: 5,
}

// TODO: Move
const ProfileState = {
	onTabChange: () => {},
}
export const ProfileContext = createContext(ProfileState)

const MyProfile = () => {
	const { user } = useAuth0()
	const parsed = queryString.parse(location.hash)

	const checkURL = () => {
		if (parsed.billing === null) return TabId.BILLING
		if (parsed.notification === null) return TabId.NOTIFICATIONS
		if (parsed.certificates === null) return TabId.CERTIFICATES
		if (parsed.resources === null) return TabId.RESOURCES

		return 1
	}

	const [openTab, setOpenTab] = useState(checkURL() || 1)

	return (
		<Layout>
			<main className="bg-gray-100 min-h-screen">
				<section className="max-w-7xl mx-auto pb-10 lg:py-12 lg:px-8">
					<div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
						<SideNav setOpenTab={setOpenTab} openTab={openTab} />
						<div className="sm:px-6 lg:px-0 lg:col-span-9">
							<ProfileContext.Provider value={{ onTabChange: openTab }}>
								<ProfileDetailsSection
									current={openTab === TabId.PROFILE ? "block" : "hidden"}
								/>
								<BillingSection
									current={openTab === TabId.BILLING ? "block" : "hidden"}
								/>
								<NotificationsSection
									current={openTab === TabId.NOTIFICATIONS ? "block" : "hidden"}
								/>
								<CertificateSection
									current={openTab === TabId.CERTIFICATES ? "block" : "hidden"}
								/>
								<ResourcesSection
									current={openTab === TabId.RESOURCES ? "block" : "hidden"}
								/>
							</ProfileContext.Provider>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	)
}

export default withAuthenticationRequired(MyProfile, {
	onRedirecting: () => <Redirecting />,
})
