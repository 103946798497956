import React, {
  useContext,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react";

import BillingTable from "../billingTable";
import NumberFormat from "react-number-format";
import ProfileCard from "./profileCard";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { navigate } from "gatsby";
import { ProfileContext } from "../../pages/myprofile";
import Cookies from "js-cookie";

const BillingSection = ({ open, current }) => {
  const { onTabChange } = useContext(ProfileContext);
  const { getIdTokenClaims } = useAuth0();
  const [invoices, setInvoices] = useState([]);
  const [subInfo, setSubInfo] = useState({});
  const [renewError, setRenewError] = useState(false);
  const [renewSuccess, setRenewSuccess] = useState(false);

  const loaded = useRef(false);

  //? NOTE: A Nice to have - list all current Orders that have been opened.
  const getSub = async () => {
    try {
      const tokenClaims = await getIdTokenClaims();

      const response = await axios.get(
        `${process.env.GATSBY_API_BASE_URL}/Member/GetMemberSubscription`,
        {
          headers: {
            Authorization: `Bearer ${tokenClaims.__raw}`,
          },
        }
      );

      if (response.status) {
        setSubInfo(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const RenewSubscription = async () => {
    const tokenClaims = await getIdTokenClaims();

    const response = await axios.post(
      `${process.env.GATSBY_API_BASE_URL}/Member/RenewMemberSubscription`,
      {},
      {
        headers: {
          Authorization: `Bearer ${tokenClaims.__raw}`,
        },
      }
    );

    if (response.status && response.data) {
      setRenewError(false);
      setPreventRenewalCookie();
      setRenewSuccess(true);
      await getSub();
    } else {
      setRenewError(true);
      setRenewSuccess(false);
    }
  };

  const getInvoices = async () => {
    try {
      const tokenClaims = await getIdTokenClaims();

      const response = await axios.get(
        `${process.env.GATSBY_API_BASE_URL}/Member/GetMemberInvoices`,
        {
          headers: {
            Authorization: `Bearer ${tokenClaims.__raw}`,
          },
        }
      );

      if (response.status) {
        // Format the data to be displayed in the table

        const invoices = response.data.map((invoice) => {
          return {
            invoiceNumber: invoice.invoiceNumber,
            invoiceDate: invoice.datePurchased,
            invoiceRef: invoice.orderReferenceNumber,
            invoiceAmount: invoice.totalPrice,
            invoiceStatus: invoice.currentStatus,
            invoiceId: invoice.invoiceId,
          };
        });

        setInvoices(invoices);
      }
    } catch (err) {
      // console.log(err)
    }
  };

  useEffect(async () => {
    if (current !== "hidden") {
      // console.log("Billing page hit")
      await getInvoices();
      await getSub();
    }
  }, [onTabChange]);

  const BillingTableConfig = {
    headers: [
      {
        label: "Invoice #",
        accessor: "invoiceNumber",
        converter: (value) => <strong>{value}</strong>,
      },
      {
        label: "Date",
        hidden: true,
        accessor: "invoiceDate",
        converter: (date) => <DateTime time={date} />,
        sort: (body, asc) => {
          // Sort by status names These are only ever "Active" or "Paid"
          if (asc) {
            return body.sort((a, b) => {
              if (a && b) {
                return new Date(a.invoiceDate) - new Date(b.invoiceDate);
              }
              return 0;
            });
          }
          return body.sort((a, b) => {
            if (a && b) {
              return new Date(b.invoiceDate) - new Date(a.invoiceDate);
            }
            return 0;
          });
        },
      },
      {
        label: "Reference #",
        hidden: true,
        accessor: "invoiceRef",
      },
      {
        label: "Status",
        hidden: true,
        accessor: "invoiceStatus",
        sort: (body, asc) => {
          // Sort by status names These are only ever "Active" or "Paid"
          if (asc) {
            return body.sort((a, b) =>
              a.invoiceStatus.toLowerCase() === "active" ? -1 : 1
            );
          }
          return body.sort((a, b) =>
            a.invoiceStatus.toLowerCase() === "active" ? 1 : -1
          );
        },
      },
      {
        label: "Amount",
        accessor: "invoiceAmount",
        converter: (value) => (
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={"$"}
          />
        ),
      },
    ],
    body: invoices,
  };

  return (
    <div className={current}>
      <ProfileCard title="Subscription">
        <ProfileCard.Description description="Your current active subscription" />
        <ProfileCard.Item>
          <Subscription
            subInfo={subInfo}
            renewSub={RenewSubscription}
            renewError={renewError}
            renewSuccess={renewSuccess}
          />
        </ProfileCard.Item>
      </ProfileCard>

      {/* <ProfileCard title="Incomplete Orders">
				<ProfileCard.Description description="All your Incomplete Orders" />
				<ProfileCard.Item>
					<div className="mt-6 grid grid-cols-4 gap-6">Billing Info here</div>
				</ProfileCard.Item>
			</ProfileCard> */}

      <ProfileCard title="Invoices">
        <ProfileCard.Description description="View your Invoices Below." />
        <ProfileCard.Item>
          <BillingTable Config={BillingTableConfig} />
        </ProfileCard.Item>
      </ProfileCard>
    </div>
  );
};

export default BillingSection;

const DateTime = ({ time }) => {
  if (!time) {
    return <span />;
  }

  const shortFormat = new Intl.DateTimeFormat("en-AU", {
    timeZone: "Australia/NSW",
    year: "numeric",
    month: "numeric",
    day: "2-digit",
    hour12: true,
  });

  return shortFormat.format(new Date(time));
};

// Set a 5 minute cookie
const setPreventRenewalCookie = () => {
  Cookies.set("preventRenewal", "true", { expires: 1 / 288 }); // 5 minutes is 1/288th of a day
};

const Subscription = ({ subInfo, renewSub, renewError, renewSuccess }) => {
  const [displayRenew, setDisplayRenew] = useState(false);
  useEffect(() => {
    // if subscription status = active outstanding -> not allow to renew
    // or subscription status = active processed but expiry date - current date< 1 month -> not allow to renew
    if (subInfo?.expiryDate) {
      const renewWindow = 60; // days
      const expiryDate = new Date(subInfo.expiryDate);
      const currentDate = new Date();

      if (
        (expiryDate - currentDate < renewWindow * 24 * 60 * 60 * 1000 ||
          expiryDate < currentDate) &&
        !Cookies?.get("preventRenewal")
      ) {
        setDisplayRenew(true);
      } else {
        setDisplayRenew(false);
      }
    }
  }, [subInfo]);

  if (subInfo?.membershipName) {
    return (
      <>
        <div className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-4">
          <div className="font-bold">{subInfo.membershipName}</div>
          <span>
            <strong>Start Date</strong> - <DateTime time={subInfo.startDate} />
          </span>
          <span>
            <strong>End Date</strong> - <DateTime time={subInfo.expiryDate} />
          </span>
        </div>
        <div>
          {displayRenew ? (
            <button
              onClick={renewSub}
              type="button"
              className={
                "bg-primary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              }
            >
              Renew Membership
            </button>
          ) : null}
          {renewError ? (
            <div className="text-error">
              An error occurred when renewing your membership. Please email:{" "}
              <a className="text-blue-600" href="mailto:nsw@ipweansw.org">
                nsw@ipweansw.org
              </a>
            </div>
          ) : null}
          {renewSuccess ? (
            <div className="text-success">
              Your membership renewal process has started! Please check your
              email or below for an invoice. If you do not receive an email,
              please email:{" "}
              <a className="text-blue-600" href="mailto:nsw@ipweansw.org">
                nsw@ipweansw.org
              </a>
            </div>
          ) : null}
        </div>
      </>
    );
  }

  return (
    <div className="mt-6 grid grid-cols-4 gap-6">
      <div>Not Currently a member</div>
    </div>
  );
};
