import React from "react"
import ProfileCard from "./profileCard"

const CommitteesSection = ({ open, current }) => {
	return (
		<div className={current}>
			<ProfileCard title="Certificates">
				<ProfileCard.Description description="View your Committees here." />
				<ProfileCard.Item>
					<div className="mt-6 grid grid-cols-4 gap-6">Committees here</div>
				</ProfileCard.Item>
			</ProfileCard>
		</div>
	)
}

export default CommitteesSection
