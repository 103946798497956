import { FieldArray, Form, Formik } from "formik"
import React, { useEffect, useRef, useState, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"

import CustomerPreferenceFormGroup from "./formGroups/customerPreferenceFormGroup"
import InterestGroupFormGroup from "./formGroups/interestGroupFormGroup"
import ProfileCard from "./profileCard"
import SaveNotification from "./saveNotification"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import { ProfileContext } from "../../pages/myprofile"

const NotificationsSection = ({ open, current }) => {
	const [communicationPrefs, setCommunicationPrefs] = useState({
		doNotBulkEmail: false,
		doNotBulkMail: false,
	})

	const loaded = useRef(false)

	const [show, setShow] = useState(false)
	const [result, setResult] = useState(false)
	const [submittingIG, setSubmittingIG] = useState(false)
	const [submittingCP, setSubmittingCP] = useState(false)
	const [interestGroups, setInterestGroups] = useState([
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
	])

	const { getIdTokenClaims } = useAuth0()
	const interestGroupsFormRef = useRef()
	const customerPrefFormRef = useRef()

	const data = useStaticQuery(graphql`
		{
			allInterestGroup(filter: { isRTDFilter: { eq: false } }) {
				nodes {
					interestGroupId
					name
					permalink
				}
			}
		}
	`)

	const allInterestGroups = data.allInterestGroup.nodes
	const { onTabChange } = useContext(ProfileContext)

	useEffect(async () => {
		if (current !== "hidden") {
			// console.log("Notifications hit")
			await getCommunicationPrefs()
			await getInterestGroups()
		}
	}, [onTabChange])

	const getCommunicationPrefs = async () => {
		const tokenClaims = await getIdTokenClaims()
		try {
			const { data } = await axios.get(
				`${process.env.GATSBY_API_BASE_URL}/Member/GetCommunicationPrefs`,
				{
					headers: {
						Authorization: `Bearer ${tokenClaims.__raw}`,
					},
				}
			)

			if (data) {
				setCommunicationPrefs(data)
			}
		} catch (err) {
			// console.error(`Error getting communication preferences ${err}`)
		}
	}

	const handleCommunicationPrefsSubmit = async values => {
		if (values) {
			try {
				setSubmittingCP(true)
				const tokenClaims = await getIdTokenClaims()
				const { status } = await axios.post(
					`${process.env.GATSBY_API_BASE_URL}/Member/UpdateCommunicationPrefs`,
					{
						details: values,
					},
					{
						headers: {
							Authorization: `Bearer ${tokenClaims.__raw}`,
						},
					}
				)

				if (status) {
					setSubmittingCP(false)
					setResult(true)
					setShow(true)
				}
			} catch (err) {
				setSubmittingCP(false)
				setResult(false)
				setShow(true)
				// console.error(`Error updating communication preferences ${err}`)
			}
		}
	}

	const getInterestGroups = async () => {
		const tokenClaims = await getIdTokenClaims()
		try {
			const { data } = await axios.get(
				`${process.env.GATSBY_API_BASE_URL}/Member/GetMemberInterestGroups`,
				{
					headers: {
						Authorization: `Bearer ${tokenClaims.__raw}`,
					},
				}
			)

			if (data) {
				const mapData = allInterestGroups.map((item, idx) =>
					data.find(i => i.interestId === item.interestGroupId) ? true : false
				)
				setInterestGroups(mapData)
			}
		} catch (err) {
			// console.error(`Error getting communication preferences ${err}`)
		}
	}

	const handleInterestGroupsSubmit = async values => {
		if (values) {
			try {
				setSubmittingIG(true)
				const interestGroups = []
				await values.map((val, idx) => {
					if (val) {
						interestGroups.push({
							interestId: allInterestGroups[idx].interestGroupId,
							selected: true,
						})
					} else {
						interestGroups.push({
							interestId: allInterestGroups[idx].interestGroupId,
							selected: false,
						})
					}
				})

				const tokenClaims = await getIdTokenClaims()
				const { status } = await axios.post(
					`${process.env.GATSBY_API_BASE_URL}/Member/UpdateInterestGroups`,
					{
						groups: interestGroups,
					},
					{
						headers: {
							Authorization: `Bearer ${tokenClaims.__raw}`,
						},
					}
				)

				if (status) {
					setSubmittingIG(false)
					setResult(true)
					setShow(true)
				}
			} catch (err) {
				setSubmittingIG(false)
				setResult(false)
				setShow(true)
				// console.error(err)
			}
		}
	}

	return (
		<div className={current}>
			<SaveNotification
				show={show}
				setShow={setShow}
				duration={3000}
				result={result}
			/>
			<ProfileCard
				title="Interest Groups"
				savebtn={true}
				formRef={interestGroupsFormRef}
				submitting={submittingIG}
				onlySubmitWhenDirty={true}
			>
				<ProfileCard.Description description="View and update your Interest Groups here." />
				<ProfileCard.Item>
					<Formik
						innerRef={interestGroupsFormRef}
						enableReinitialize={true}
						initialValues={interestGroups}
						onSubmit={values => {
							// console.log(values)
							handleInterestGroupsSubmit(values)
						}}
					>
						<Form className="mt-6 grid grid-cols-4 gap-6">
							<InterestGroupFormGroup
								allGroups={allInterestGroups}
								list={interestGroups}
							/>
						</Form>
					</Formik>
				</ProfileCard.Item>
			</ProfileCard>

			<ProfileCard
				title=" Communication Preferences"
				savebtn={true}
				formRef={customerPrefFormRef}
				submitting={submittingCP}
				onlySubmitWhenDirty={true}
			>
				<ProfileCard.Description
					description="Please check the box for the topics you would like to
					receive updates on. We send updates regularly however you can unsusbscribe
					any time by visiting this page or clicking 
					on the 'update your communication preferences link' in the newsletter."
				/>
				<ProfileCard.Item>
					<Formik
						innerRef={customerPrefFormRef}
						enableReinitialize={true}
						initialValues={communicationPrefs}
						onSubmit={values => {
							// console.log(values)
							handleCommunicationPrefsSubmit(values)
						}}
					>
						<Form className="mt-6 grid grid-cols-4 gap-6">
							<CustomerPreferenceFormGroup />
						</Form>
					</Formik>
				</ProfileCard.Item>
			</ProfileCard>
		</div>
	)
}

export default NotificationsSection

//<InterestGroupFormGroup list={allInterestGroups} />
