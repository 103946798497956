import Loader from "react-loader-spinner"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import { useState } from "react"

const InvoiceDownload = ({ invoiceId }) => {
	const { getIdTokenClaims } = useAuth0()
	const [clicked, setClicked] = useState(false)

	function classNames(...classes) {
		return classes.filter(Boolean).join(" ")
	}

	const handleClick = async e => {
		e.preventDefault()
		setClicked(true)
		const tokenClaims = await getIdTokenClaims()

		await axios
			.get(
				`${process.env.GATSBY_API_BASE_URL}/Resources/DownloadInvoice/${invoiceId}`,
				{
					headers: {
						Authorization: `Bearer ${tokenClaims?.__raw}`,
					},
					responseType: "blob",
				}
			)
			.then(handleDownload)
			.catch(catchError)
	}

	const catchError = e => {
		// Log Error.
		setClicked(false)
	}

	const handleDownload = async response => {
		const contentType = response.headers["content-type"]
		const url = window.URL.createObjectURL(
			new Blob([response.data], { type: contentType })
		)

		const link = document.createElement("a")
		link.href = url
		link.setAttribute("download", "invoice")
		document.body.appendChild(link)
		link.click()
		setClicked(false)
	}

	return (
		<button
			type="button"
			onClick={handleClick}
			disabled={clicked}
			className={classNames(
				clicked && `cursor-not-allowed`,
				"inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
			)}
		>
			{clicked && (
				<Loader type="TailSpin" color="#00BFFF" height={20} width={20} />
			)}
			Download Invoice
		</button>
	)
}
export default InvoiceDownload
