import * as yup from "yup"

import { Form, Formik } from "formik"
import React, { useEffect, useRef, useState, useContext } from "react"

import AdditionalDetailsFormGroup from "./formGroups/additionalDetailsFormGroup"
import DetailsFormGroup from "./formGroups/detailsFormGroup"
import EmployementList from "./employementList"
import ProfileCard from "./profileCard"
import SaveNotification from "./saveNotification"
import axios from "axios"
import moment from "moment"
import { useAuth0 } from "@auth0/auth0-react"
import { ProfileContext } from "../../pages/myprofile"

const validationSchema = yup.object({
	firstName: yup
		.string("Enter your given names")
		.required("First name is required"),
	lastName: yup
		.string("Enter your family names")
		.required("Last name is required"),
	emailAddress: yup
		.string("Enter an email address")
		.email()
		.required("Email address is required"),
	birthDate: yup
		.string("Enter or Select a date")
		.required("Birth date is required"),
	mobilePhone: yup.string("Phone # is required"),
	telephone: yup.string("Telephone # is required"),
	gender: yup.string().required("Gender is required"),
	street1: yup.string().required("street1 is required"),
	street2: yup.string().nullable(),
	city: yup.string().required("city is required"),
	state: yup.string().required("state is required"),
	postCode: yup.number().positive().required("postCode is required"),
	country: yup.string().required("country is required"),
})

const ProfileDetailsSection = ({ current }) => {
	const loaded = useRef(false)
	const [submitting, setSubmitting] = useState(false)
	const [show, setShow] = useState(false)
	const [result, setResult] = useState(false)
	const { getIdTokenClaims } = useAuth0()
	const [userDetails, setUserDetails] = useState({
		firstName: "",
		lastName: "",
		emailAddress: "",
		birthDate: "",
		mobilePhone: "",
		telephone: "",
		gender: "",
		street1: "",
		street2: "",
		city: "",
		state: "",
		postCode: "",
		country: "",
	})
	const [employmentHistory, setEmploymentHistory] = useState([])
	const formRef = useRef()
	const additionalFormRef = useRef()
	const { onTabChange } = useContext(ProfileContext)

	useEffect(async () => {
		if (current !== "hidden") {
			// console.log("Details page hit")
			await getContactDetails()
			await getEmploymentHistory()
		}
	}, [onTabChange])

	const removeDetails = ({ address, birthDate, ...rest }) => rest

	const clean = obj => {
		for (let i in obj) {
			obj[i] = obj[i] ?? ""
		}
		return obj
	}

	const getContactDetails = async () => {
		const tokenClaims = await getIdTokenClaims()

		try {
			const { data } = await axios.get(
				`${process.env.GATSBY_API_BASE_URL}/Member/GetMemberDetailsById`,
				{
					headers: {
						Authorization: `Bearer ${tokenClaims.__raw}`,
					},
				}
			)

			if (data) {
				const bDate = moment(data.birthDate).format("DD/MM/yyyy")

				const flattern = clean({
					street1: data.address.street1,
					street2: data.address.street2,
					city: data.address.city,
					state: data.address.state ?? "ACT",
					postCode: data.address.postCode,
					country: data.address.country,
					birthDate: data.birthDate,
					...removeDetails(data),
				})

				flattern.gender = !flattern.gender ? "Male" : data.gender

				setUserDetails({
					...flattern,
				})
			}
		} catch (err) {
			// console.error(`Error getting Member details ${err}`)
		}
	}

	const getEmploymentHistory = async () => {
		const tokenClaims = await getIdTokenClaims()

		try {
			const { data } = await axios.get(
				`${process.env.GATSBY_API_BASE_URL}/Member/GetEmploymentHistory`,
				{
					headers: {
						Authorization: `Bearer ${tokenClaims.__raw}`,
					},
				}
			)

			if (data) {
				//Todo: Make sure that jobDescription is not null
				const newData = data.map(item => ({
					...item,
					jobDescription: item.jobDescription ?? "",
				}))
				// console.log(newData)
				setEmploymentHistory([...newData])
			}
		} catch (err) {
			// console.error("Error getting Member details" + err)
		}
	}

	const handleSubmit = async value => {
		if (value) {
			try {
				setSubmitting(true)
				const { street1, street2, city, state, postCode, country, ...details } =
					value

				const newDetails = {
					...details,
					birthDate: details.birthDate ? details.birthDate : null,
					address: {
						street1: street1,
						street2: street2,
						city: city,
						state: state,
						postCode: postCode,
						country: country,
					},
				}

				const tokenClaims = await getIdTokenClaims()
				const res = await axios.post(
					`${process.env.GATSBY_API_BASE_URL}/Member/UpdateMemberDetails`,
					{
						details: newDetails,
					},
					{
						headers: {
							Authorization: `Bearer ${tokenClaims.__raw}`,
						},
					}
				)
				if (res.status) {
					await getContactDetails()
					setSubmitting(false)
					setResult(true)
					setShow(true)
				}
			} catch (err) {
				setSubmitting(false)
				setResult(false)
				setShow(true)
			}
		}
	}

	const handleEmploymentSubmit = async (value, formRef) => {
		if (value) {
			// console.log(value)
			try {
				const { endDate, startDate, ...rest } = value

				const formatValue = {
					...rest,
					startDate: value.startDate ? moment(value.startDate).format() : null,
					endDate: value.endDate ? moment(value.endDate).format() : null,
				}

				const tokenClaims = await getIdTokenClaims()
				const res = await axios.post(
					`${process.env.GATSBY_API_BASE_URL}/Member/UpsertEmploymentHistory`,
					{
						history: [formatValue],
					},
					{
						headers: {
							Authorization: `Bearer ${tokenClaims.__raw}`,
						},
					}
				)
				if (res.status) {
					await getEmploymentHistory()
					setResult(true)
					setShow(true)
				}
			} catch (err) {
				setResult(false)
				setShow(true)
				// console.error(err)
			}
		}
	}

	const handleDeleteItem = async (e, value) => {
		e.preventDefault()

		if (value) {
			// console.log(value)
			//Remove the item from the employmentHistory array
			const newHistory = employmentHistory.filter(item => item.id !== value.id)
			setEmploymentHistory([...newHistory])
			try {
				const tokenClaims = await getIdTokenClaims()
				const res = await axios.delete(
					`${process.env.GATSBY_API_BASE_URL}/Member/${value}/DeleteEmploymentHistoryItem`,
					{
						headers: {
							Authorization: `Bearer ${tokenClaims.__raw}`,
						},
					}
				)
				if (res.status) {
					await getEmploymentHistory()
					setResult(true)
					setShow(true)
				}
			} catch (err) {
				setResult(false)
				setShow(true)
				// console.error(err)
			}
		}
	}

	return (
		<div className={current}>
			<SaveNotification
				show={show}
				setShow={setShow}
				duration={3000}
				result={result}
			/>
			<ProfileCard
				title="Profile Details"
				savebtn={true}
				formRef={formRef}
				submitting={submitting}
			>
				<ProfileCard.Description description="Update your Personal Details." />
				<ProfileCard.Item>
					<Formik
						initialValues={{ ...userDetails }}
						innerRef={formRef}
						enableReinitialize={true}
						validationSchema={validationSchema}
						onSubmit={values => {
							handleSubmit(values)
						}}
					>
						<Form className="mt-6 grid grid-cols-4 gap-6">
							<DetailsFormGroup />
						</Form>
					</Formik>
				</ProfileCard.Item>
			</ProfileCard>

			<ProfileCard
				title="Additional Information"
				savebtn={false}
				formRef={additionalFormRef}
			>
				<ProfileCard.Description description="Update your Employment details." />
				<ProfileCard.Item>
					<EmployementList
						employmentHistory={employmentHistory}
						handleEmploymentSubmit={handleEmploymentSubmit}
						handleDeleteItem={handleDeleteItem}
						saved={result}
					/>
				</ProfileCard.Item>
			</ProfileCard>
		</div>
	)
}

export default ProfileDetailsSection
