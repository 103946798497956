import React from "react"
import { useField } from "formik"

const FormikSelectInput = ({ label, ...props }) => {
	const [field, meta] = useField(props)
	return (
		<div>
			<label
				className="block text-sm font-medium text-gray-700"
				htmlFor={props.id || props.name}
			>
				{label}
			</label>
			<select
				className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
				{...field}
				{...props}
			/>
			{meta.touched && meta.error ? (
				<div className="error text-red-600">{meta.error}</div>
			) : null}
		</div>
	)
}
export default FormikSelectInput
