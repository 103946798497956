import "react-datepicker/dist/react-datepicker.css"

import { useField, useFormikContext } from "formik"

import DatePicker from "react-datepicker"
import React from "react"

const range = (start, end, step) => {
	return Array.from(
		{ length: (end - start) / step },
		(_, i) => start + i * step
	)
}

export const FormikDatePickerInput = ({ label, ...props }) => {
	const { setFieldValue } = useFormikContext()
	const [field, meta] = useField(props)
	return (
		<>
			<label
				className="block text-sm font-medium text-gray-700"
				htmlFor={props.id || props.name}
			>
				{label}
			</label>
			<DatePicker
				fixedHeight
				showMonthDropdown
				showYearDropdown
				dropdownMode="select"
				selected={field.value && new Date(field.value)}
				onMonthChange={date => {
					if (date) {
						date.setHours((-1 * date.getTimezoneOffset()) / 60)
					}
					setFieldValue(field.name, date)
				}}
				onYearChange={date => {
					if (date) {
						date.setHours((-1 * date.getTimezoneOffset()) / 60)
					}
					setFieldValue(field.name, date)
				}}
				onChange={date => {
					if (date) {
						date.setHours((-1 * date.getTimezoneOffset()) / 60)
					}
					setFieldValue(field.name, date)
				}}
				dateFormat="dd/MM/yyyy"
				className="text-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm z-50"
				popperClassName="z-50"
				popperPlacement="top-end"
			/>
			{meta.touched && meta.error ? (
				<div className="error text-red-600 ">{meta.error}</div>
			) : null}
		</>
	)
}

export default FormikDatePickerInput
