import React from "react"

const ProfileCard = ({
	children,
	title,
	savebtn,
	formRef,
	submitting,
	onlySubmitWhenDirty = false,
}) => {
	const items = React.Children.map(children, child =>
		child.type.displayName === "Item" ? child : null
	)
	const description = React.Children.map(children, child =>
		child.type.displayName === "Description" ? child : null
	)
	const save = React.Children.map(children, child => {
		child.type.displayName === "Item" && child.type === "button" ? child : null
	})

	return (
		<div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 pb-6">
			<section aria-labelledby="payment-details-heading">
				<div className="shadow sm:overflow-hidden">
					<div className="bg-white py-6 px-4 sm:p-6">
						<div>
							<h2
								id="profile-card-heading"
								className="text-lg leading-6 font-medium text-gray-900"
							>
								{title}
							</h2>
							{description}
						</div>

						<div>{items}</div>
					</div>
					<div className="px-4 py-3 text-right sm:px-6 w-full col-span-4">
						{savebtn && (
							<Save
								formRef={formRef}
								loading={submitting}
								onlySubmitWhenDirty={onlySubmitWhenDirty}
							/>
						)}
					</div>
				</div>
			</section>
		</div>
	)
}

const Description = ({ description }) => (
	<p className="mt-1 text-sm  text-gray-500">{description}</p>
)
Description.displayName = "Description"
ProfileCard.Description = Description

const Item = ({ children }) => children
Item.displayName = "Item"
ProfileCard.Item = Item

const Save = ({ loading, formRef, onlySubmitWhenDirty }) => (
	<button
		type="submit"
		className={
			loading
				? "loading disabled m-0 bg-gray-800 border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 w-1/4"
				: "m-0 bg-gray-800 border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 w-1/4"
		}
		onClick={() => {
			if (
				formRef.current.dirty &&
				!loading &&
				formRef.current &&
				Object.keys(formRef.current.errors).length === 0
			) {
				formRef.current.handleSubmit()
				// formRef.current.resetForm({ values: formRef.current.values })
			} else {
				!onlySubmitWhenDirty && formRef.current.handleSubmit()
			}
		}}
	>
		{loading ? "Saving.." : "Save"}
	</button>
)

export default ProfileCard
