import React, { useState } from "react"

import FormikDatePickerInput from "../formikTemplates/formikDatePickerInput"
import FormikSelectInput from "../formikTemplates/formikSelectInput"
import FormikTextInput from "../formikTemplates/formikTextInput"

const DetailsFormGroup = () => {
	return (
		<>
			<div className="col-span-4 sm:col-span-3">
				<FormikTextInput
					label="First Name *"
					name="firstName"
					type="text"
					autoComplete="given-name"
				/>
			</div>
			<div className="col-span-4 sm:col-span-3">
				<FormikTextInput
					label="Last Name *"
					name="lastName"
					type="text"
					autoComplete="family-name"
				/>
			</div>
			<div className="col-span-4 sm:col-span-3">
				<FormikTextInput
					label="Email Address *"
					name="emailAddress"
					type="email"
					autoComplete="email"
				/>
			</div>
			<div className="col-span-4 sm:col-span-3">
				<FormikDatePickerInput
					label="Date of Birth *"
					name="birthDate"
					autoComplete="birthDate"
				/>
			</div>
			<div className="col-span-4 sm:col-span-2">
				<FormikTextInput
					label="Mobile # *"
					name="mobilePhone"
					type="text"
					autoComplete="tel"
				/>
			</div>
			<div className="col-span-4 sm:col-span-2">
				<FormikTextInput
					label="Business Phone #"
					name="telephone"
					type="text"
					autoComplete="tel"
				/>
			</div>
			<div className="col-span-4 sm:col-span-2">
				<FormikSelectInput label="Gender *" name="gender">
					<option value="Male">Male</option>
					<option value="Female">Female</option>
					<option value="Undisclosed">Undisclosed</option>
				</FormikSelectInput>
			</div>
			<div className="col-span-4 sm:col-span-3">
				<FormikTextInput
					label="Street 1 *"
					name="street1"
					type="text"
					autoComplete=""
				/>
			</div>
			<div className="col-span-4 sm:col-span-3">
				<FormikTextInput
					label="Street 2"
					name="street2"
					type="text"
					autoComplete=""
				/>
			</div>
			<div className="col-span-4 sm:col-span-3">
				<FormikTextInput
					label="City *"
					name="city"
					type="text"
					autoComplete=""
				/>
			</div>
			<div className="col-span-4 sm:col-span-2">
				<FormikSelectInput label="State *" name="state">
					<option value="ACT">Australian Capital Territory</option>
					<option value="NSW">New South Wales</option>
					<option value="NT">Northern Territory</option>
					<option value="QLD">Queensland</option>
					<option value="SA">South Australia</option>
					<option value="TAS">Tasmania</option>
					<option value="VIC">Victoria</option>
				</FormikSelectInput>
			</div>
			<div className="col-span-4 sm:col-span-1">
				<FormikTextInput
					label="Post Code *"
					name="postCode"
					type="text"
					autoComplete=""
				/>
			</div>
			<div className="col-span-4 sm:col-span-1">
				<FormikTextInput
					label="Country *"
					name="country"
					type="text"
					autoComplete=""
				/>
			</div>
		</>
	)
}

export default DetailsFormGroup
