import React, { useEffect, useRef, useState, useContext } from "react"

import ProfileCard from "./profileCard"
import Resource from "../resources/resource"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import { load } from "dotenv"
import { ProfileContext } from "../../pages/myprofile"

const CertificatesSection = ({ open, current }) => {
	const loaded = useRef(false)
	const [resources, setResources] = useState([])
	const { getIdTokenClaims } = useAuth0()
	const { onTabChange } = useContext(ProfileContext)

	useEffect(async () => {
		if (current !== "hidden") {
			// console.log("Certs hit")
			await getMemberResources()
		}
	}, [onTabChange])

	const getMemberResources = async () => {
		// setResources([])
		const tokenClaims = await getIdTokenClaims()
		try {
			const { data } = await axios.get(
				`${process.env.GATSBY_API_BASE_URL}/Resources/ListAllMembersResources`,
				{
					headers: {
						Authorization: `Bearer ${tokenClaims.__raw}`,
					},
				}
			)

			if (data) {
				//Todo: Change this to select categories when they are working
				//Filter out resources that are not certificates
				const filteredResources = data.filter(resource =>
					resource.name.includes("Certificate")
				)
				// console.log(filteredResources)
				setResources(filteredResources)
			}
		} catch (err) {
			// console.error(`Error getting resources ${err}`)
		}
	}

	return (
		<div className={current}>
			<ProfileCard title="Certificates">
				<ProfileCard.Description description="View your Certificates here." />
				<ProfileCard.Item>
					<div className="mt-6 grid grid-cols-4 gap-6"></div>
					{resources.map(r => (
						<Resource key={r.file} resource={r} clickToDownload={true} />
					))}
				</ProfileCard.Item>
			</ProfileCard>
		</div>
	)
}

export default CertificatesSection
