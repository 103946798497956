import React, { useState } from "react"

import FormikCheckBoxInput from "../formikTemplates/formikCheckBoxInput"

const AdditionalDetailsFormGroup = () => {
	return (
		<>
			<div className="col-span-4">
				<FormikCheckBoxInput
					label="IPWEA NSW Declaration"
					description="I allow for my basic details to be included on potential delegate lists."
					moreInfo="In accordance with the Privacy Act 1998."
					name="ipweaDeclaration"
				/>
			</div>
		</>
	)
}

export default AdditionalDetailsFormGroup
