import FormikDatePickerInput from "../formikTemplates/formikDatePickerInput"
import FormikTextAreaInput from "../formikTemplates/formikTextAreaInput"
import FormikTextInput from "../formikTemplates/formikTextInput"
import React from "react"

function EmploymentDetailsFormGroup() {
	return (
		<>
			<div className="col-span-4">
				<FormikTextInput
					label="Employer Name *"
					name="employerName"
					type="text"
				/>
			</div>
			<div className="col-span-4 ">
				<FormikTextInput label="Position *" name="position" type="text" />
			</div>
			<div className="col-span-4 sm:col-span-2">
				<FormikDatePickerInput label="Start Date *" name="startDate" />
			</div>
			<div className="col-span-4 sm:col-span-2">
				<FormikDatePickerInput label="End Date" name="endDate" />
			</div>
			<div className="col-span-4 ">
				<FormikTextAreaInput
					label="Job Description"
					name="jobDescription"
					type="text"
				/>
			</div>
		</>
	)
}

export default EmploymentDetailsFormGroup
