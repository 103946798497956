import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline"
import React, { Component, useEffect } from "react"

import InvoiceDownload from "./invoiceDownload"

const BillingTable = ({ Config }) => {
	const [body, setBody] = React.useState([])
	const [sortAsc, setSortAsc] = React.useState(true)

	useEffect(() => {
		setBody(Config.body)
	}, [Config])

	useEffect(() => {}, [body])

	function classNames(...classes) {
		return classes.filter(Boolean).join(" ")
	}

	return (
		<div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:-mx-6 md:mx-0">
			<table className="min-w-full divide-y divide-gray-300">
				<thead>
					<tr>
						{Config?.headers.map((header, index) => (
							<th
								scope="col"
								className={classNames(
									header?.sort && "cursor-pointer",
									header?.hidden
										? "hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
										: "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
								)}
								key={index}
								onClick={() => {
									if (header?.sort) {
										setSortAsc(!sortAsc)
										setBody(header.sort(body, sortAsc))
									}
								}}
							>
								{header?.sort ? (
									<div className="flex justify-center">
										{header.label}
										{header.sort && sortAsc ? (
											<ChevronDownIcon className="h-4 w-4 " />
										) : (
											<ChevronUpIcon className="h-4 w-4" />
										)}
									</div>
								) : (
									<div className="flex justify-center">{header.label}</div>
								)}
							</th>
						))}
						<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
							<span className="sr-only">Download</span>
						</th>
					</tr>
				</thead>
				<tbody>
					{body.map((body, index) => {
						return (
							<tr key={index}>
								{Config?.headers?.map((header, idx) => {
									const Item = body[header.accessor]
									// console.log(Item)

									if (header.hidden) {
										return (
											<td
												key={idx}
												className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
											>
												{header?.converter ? header.converter(Item) : Item}
											</td>
										)
									}
									return (
										<td
											key={idx}
											className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm"
										>
											{header?.converter ? header.converter(Item) : Item}
										</td>
									)
								})}
								<td>
									<InvoiceDownload invoiceId={body.invoiceId} />
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}

export default BillingTable
