import React, { useEffect } from "react"
import { useField, useFormikContext } from "formik"

const FormikCheckBoxInput = ({ label, description, moreInfo, ...props }) => {
	const [field, meta] = useField(props)
	const { setFieldValue } = useFormikContext()
	return (
		<>
			<label
				className="block text-sm font-medium text-gray-700"
				htmlFor={props.id || props.name}
			>
				<input
					type="checkbox"
					checked={field.value}
					className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
					{...field}
					{...props}
				/>
				<span className="align-middle pl-2">{label}</span>
			</label>
			<p className="overflow">
				{description && <span className="text-red-500">*</span>}
				{description}
				<br />
				<span className="text-xs">{moreInfo}</span>
			</p>
			{meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null}
		</>
	)
}
export default FormikCheckBoxInput
