import React from "react"
import { useField } from "formik"

const FormikTextAreaInput = ({ label, autoComplete, ...props }) => {
	const [field, meta] = useField(props)
	return (
		<>
			<label
				className="block text-sm font-medium text-gray-700"
				htmlFor={props.id || props.name}
			>
				{label}
			</label>
			<textarea
				autoComplete={autoComplete}
				className="text-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
				{...field}
				{...props}
			/>
			{meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null}
		</>
	)
}
export default FormikTextAreaInput
