import { CalendarIcon, TrashIcon, UsersIcon } from "@heroicons/react/solid"
import React, { useState } from "react"

import ProfileModal from "./profileModal"

const EmployementList = ({
	employmentHistory,
	handleEmploymentSubmit,
	handleDeleteItem,
	saved,
}) => {
	const [open, setOpen] = useState(false)
	const [itemDetails, setItemDetails] = useState({
		employerName: "",
		position: "",
		startDate: "",
		endDate: "",
		jobDescription: "",
	})
	const handleClick = (e, item) => {
		e.preventDefault()
		setItemDetails({ ...item })
		setOpen(!open)
	}

	const addHistory = () => {
		setOpen(true)
		setItemDetails({
			employerName: "",
			position: "",
			startDate: "",
			endDate: "",
			jobDescription: "",
		})
	}

	return (
		<>
			<ProfileModal
				open={open}
				saved={saved}
				setOpen={setOpen}
				employmentItem={itemDetails}
				setEmploymentItem={setItemDetails}
				submitEmploymentHistory={handleEmploymentSubmit}
			/>
			<Header title="Employment Details" />

			<button
				type="button"
				className="mt-3 w-full justify-end rounded-md border border-gray-300 "
				onClick={addHistory}
			>
				Add
			</button>

			<div className="bg-white shadow overflow-hidden border ">
				<ul role="list" className="divide-y divide-gray-200">
					{employmentHistory.length > 0 &&
						employmentHistory.map((item, idx) => (
							<li key={item.id || idx} className="flex flex-row">
								<a
									onClick={e => handleClick(e, item)}
									className="flex-grow cursor-pointer inline-block hover:bg-gray-50"
								>
									<div className="px-4 py-4 sm:px-6">
										<div className="flex items-center justify-between">
											<p className="text-sm font-medium text-indigo-600 truncate">
												{item.position}
											</p>
											{!item.endDate ? (
												<div className="ml-2 flex-shrink-0 flex">
													<p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
														Current
													</p>
												</div>
											) : (
												<div className="ml-2 flex-shrink-0 flex">
													<p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
														Finished
													</p>
												</div>
											)}
										</div>

										<div className="mt-2 sm:flex sm:justify-between">
											<div className="sm:flex">
												<p className="flex items-center text-sm text-gray-500">
													<UsersIcon
														className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
														aria-hidden="true"
													/>
													{item.employerName}
												</p>
											</div>
											<div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
												<CalendarIcon
													className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
													aria-hidden="true"
												/>
												<p>
													<DateInformation
														from={item.startDate}
														to={item.endDate}
													/>
												</p>
											</div>
										</div>
									</div>
								</a>
								<a
									onClick={e => {
										handleDeleteItem(e, item.id)
									}}
									className="m-auto inline-block align-middle flex-none cursor-pointer  hover:bg-gray-50"
								>
									<TrashIcon className=" h-10 w-10" />
								</a>
							</li>
						))}
				</ul>
			</div>
		</>
	)
}

const Header = ({ title }) => (
	<h1 className="text-lg leading-6 font-medium text-gray-900 pt-5">{title}</h1>
)

const DateInformation = ({ from, to }) => {
	const commencingFrom = new Intl.DateTimeFormat("en-AU", {
		timeZone: "Australia/NSW",
		month: "short",
		day: "2-digit",
	}).format(new Date(from))

	const commencingTo = to
		? new Intl.DateTimeFormat("en-AU", {
				timeZone: "Australia/NSW",
				year: "numeric",
				month: "short",
				day: "2-digit",
		  }).format(new Date(to))
		: ""

	return to ? (
		<strong>
			{commencingFrom} - {commencingTo}
		</strong>
	) : (
		<span>From {commencingFrom} - Current</span>
	)
}

export default EmployementList
