import React from "react"
import FormikCheckBoxInput from "../formikTemplates/formikCheckBoxInput"
import { useFormikContext } from "formik"

const InterestGroupFormGroup = ({ allGroups }) => {
	return (
		<>
			{allGroups.map((item, idx) => (
				<div key={idx} className="col-span-4 sm:col-span-2">
					<FormikCheckBoxInput label={item.name} name={`${idx}`} />
				</div>
			))}
		</>
	)
}

export default InterestGroupFormGroup
