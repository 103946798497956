import React, { useState } from "react"
import FormikCheckBoxInput from "../formikTemplates/formikCheckBoxInput"

const CustomerPreferenceFormGroup = () => {
	return (
		<>
			<div className="col-span-4 sm:col-span-1">
				<FormikCheckBoxInput label="Do Not Bulk Email" name="doNotBulkEmail" />
			</div>
			<div className="col-span-4 sm:col-span-1">
				<FormikCheckBoxInput label="Do Not Bulk Mail" name="doNotBulkMail" />
			</div>
		</>
	)
}

export default CustomerPreferenceFormGroup
